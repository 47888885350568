import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import { observable, makeObservable, action, runInAction } from 'mobx';
import { IAskError, ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import { Constructable } from '@monorepo/tools/src/lib/interfaces/class';

// type Parameter<T> = T extends (arg: infer T) => any ? T : never;

// function call<F extends (arg: any) => any>(func: F, arg: Parameter<F>): ReturnType<F> {
// 	return func(arg);
// }

// function call<F extends (arg1?: A, arg2?: B) => void, A = void, B = void>(f: F, arg1?: A, arg2?: B) {
// 	return f(arg1, arg2);
// }

// const fn = (input: number): number => input * 2;
// const result = call(fn, 2); // Valid
// const result2 = call(fn, '2'); // Argument of type '"2"' is not assignable to parameter of type 'number'.(2345)

export interface IHttpStoreOptions<TRequestParams, TResponse, TError> {
	httpFunc: (params: TRequestParams, options?: IRequestOptions) => Promise<TResponse>;
	model?: Constructable<TResponse>;
	errorModel?: Constructable<TError>;
}

export interface IRequestFlags {
	appendData?: boolean;
	resetOffset?: boolean;
}

export class HttpStore<TRequestParams, TResponse, TError = IAskError> {
	isLoading = true;
	isSuccess = false;
	httpError: TError | null = null;
	isLocalCache = false;
	errorModel?: Constructable<TError>;
	httpFunc: (params: TRequestParams, options?: IRequestOptions) => Promise<TResponse>;
	model?: Constructable<TResponse>;
	data: TResponse | null;
	abortController = new AbortController();
	// isAborted = false;

	constructor(props: IHttpStoreOptions<TRequestParams, TResponse, TError>) {
		const { httpFunc, model, errorModel } = props;
		this.httpFunc = httpFunc;
		if (model) {
			this.model = model;
		}
		this.data = null;
		this.errorModel = errorModel;

		makeObservable(this, {
			isLoading: observable,
			isSuccess: observable,
			httpError: observable,
			data: observable,
			isLocalCache: observable,
			setIsLoading: action,
			setIsSuccess: action,
			setIsLocalCache: action,
			setHttpError: action,
			setData: action,
		});
	}

	fetch(params: TRequestParams, options?: IRequestOptions, flags?: IRequestFlags): Promise<TResponse | null> {
		const { appendData = true } = flags || { appendData: true };

		this.setHttpError(null);
		if (this.getIsLocalCache()) {
			// this.setIsLoading(false);
			return Promise.resolve(this.getData());
		}

		this.setIsLocalCache(true);
		this.setIsLoading(true);

		this.abortController = new AbortController();

		ask.addSignal(this.abortController.signal);

		return this.httpFunc(params, options)
			.then(res => {
				const data = this.model ? (new this.model(res) as TResponse) : (res as TResponse);
				runInAction(() => {
					this.setIsLocalCache(false);
					// this.setIsAborted(false);
					this.setData(data, appendData);

					this.setIsLoading(false);
					this.setIsSuccess(true);
				});
				return data;
			})
			.catch((error: IAskError) => {
				runInAction(() => {
					this.setIsLocalCache(false);
					this.setIsSuccess(false);
					this.setHttpError(
						this.errorModel ? new this.errorModel({ ...error.data, httpStatus: error.response?.status }) : (error as TError)
					);

					const isAborted = this.httpError === 'external abort';
					this.setData(null);

					if (!isAborted) {
						this.setIsLoading(false);
					}
				});
				return null;
			});
	}

	public abort(reason?: string) {
		this.abortController.abort(reason || 'external abort');
		this.setIsLocalCache(false);
		// this.setIsAborted(true);
	}

	public reset() {
		this.setData(null);
	}

	public getIsLoading(): boolean {
		return this.isLoading;
	}

	public setIsLoading(isLoading: boolean) {
		this.isLoading = isLoading;
	}

	public setIsSuccess(isSuccess: boolean) {
		this.isSuccess = isSuccess;
	}

	public getIsSuccess(): boolean {
		return this.isSuccess;
	}

	public setIsLocalCache(isLocalCache: boolean) {
		this.isLocalCache = isLocalCache;
	}

	public getIsLocalCache(): boolean {
		return this.isLocalCache;
	}

	public getHttpError(): TError | null {
		return this.httpError;
	}

	public setHttpError(httpError: TError | null) {
		this.httpError = httpError;
	}

	// blame yaron and the gerby
	public setData(data: TResponse | null, append?: boolean) {
		if ((data as IDataRequest)?.data && append && (this?.data as IDataRequest)?.data?.length > 0) {
			const d: IDataRequest = data as IDataRequest;
			const td: IDataRequest = this.data as IDataRequest;
			if (this?.data && d?.data) {
				td.data = [...td.data, ...d.data];
				return;
			}
		} else {
			this.data = data;
		}
	}

	public getData(): TResponse | null {
		return this.data;
	}

	// public setIsAborted(isAborted: boolean) {
	// 	this.isAborted = isAborted;
	// }

	// public getIsAborted(): boolean {
	// 	return this.isAborted;
	// }
}

export interface IDataRequest {
	data?: any;
}
