import { makeAutoObservable } from 'mobx';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { FilterStore } from '@monorepo/controlled/src/stores/filter.store';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { filterPrototypesByProp } from '../../components/filters-menu/filters';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { IPerformanceOptions } from '../../hooks/apis/use-reports-list';
import { ReportResponse } from '../../models/report.model';
import {
	IDomainCampaignPerformance,
	IDomainCampaignsStatus,
	IDomainHistory,
	IDomainPerformance,
	IDomainPertargetingStatus,
	IDomainScore,
} from '../../models/domain.models';
import { AdminxReporterApi } from '../../apis/adminx-reporter.api';
import { ReportTableStore } from '../../components/report-table/stores/table-stores/old-report-table.store';
import { domainCampaignsPerformanceTableConfig } from '../../components/report-table/configurations/old-report/domains/campaigns.config';
import { domainsPerformanceTableConfig } from '../../components/report-table/configurations/old-report/domains/domains.config';
import { domainScoresTableConfig } from '../../components/report-table/configurations/old-report/domains/scores.config';
import { domainHistoryTableConfig } from '../../components/report-table/configurations/old-report/domains/history.config';
import { domainsCampaignsStatusTableConfig } from '../../components/report-table/configurations/old-report/domains/campaign-status.config';
import { domainPretargetingStatusTableConfig } from '../../components/report-table/configurations/old-report/domains/pretargeting-status.config';

export enum DomainFiltersStoreKeys {
	Domains = 'domains',
}

export class DomainsStore {
	settingsStore: SettingsStore;
	list = new HttpStore<IPerformanceOptions, ReportResponse<IDomainPerformance>>({
		httpFunc: AdminxReporterApi.report,
		model: ReportResponse<IDomainPerformance>,
	});

	domainsReport = new ReportTableStore<IDomainCampaignPerformance>(domainsPerformanceTableConfig);
	scoreTable = new ReportTableStore<IDomainScore>(domainScoresTableConfig);
	historyTable = new ReportTableStore<IDomainHistory>(domainHistoryTableConfig);
	campaignStatusTable = new ReportTableStore<IDomainCampaignsStatus>(domainsCampaignsStatusTableConfig);
	pretargetingStatusTable = new ReportTableStore<IDomainPertargetingStatus>(domainPretargetingStatusTableConfig);
	campaignsPerformance = new ReportTableStore<IDomainCampaignPerformance>(domainCampaignsPerformanceTableConfig);

	constructor(settingsStore: SettingsStore) {
		makeAutoObservable(this);
		this.settingsStore = settingsStore;
		this.createDefaultFilters();
	}

	createDefaultFilters() {
		Object.values(DomainFiltersStoreKeys).map((key: DomainFiltersStoreKeys) => {
			if (!this.settingsStore.getPages().get(key)) {
				this.settingsStore.getPages().set(key, { tableStore: new TableStore() });
			}
			const domainsFilterByKey = new FilterStore({
				key,
				currentFilters: undefined,
				filterPrototypesByProp,
			});
			this.settingsStore.getPages().get(key)?.tableStore.setFiltersStore(key, domainsFilterByKey);
		});
	}
}
