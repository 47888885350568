import {
	AdvertiserIdAdxColumnConfig,
	AdvertiserNameAdxColumnConfig,
	CampaignGroupIdAdxColumnConfig,
	CampaignGroupNameAdxColumnConfig,
	CampaignIdAdxColumnConfig,
	CampaignNameAdxColumnConfig,
	DomainAdxColumnConfig,
} from '../../../columns/old-adx-report.columns';
import { generatePerformanceColumns } from '../../../columns/metrics.columns';
import { ReportSortOrder } from '../../../stores/data-stores/old-report.types';
import { ColumnVisibilityOptions } from '../../../columns/columns.types';

export const domainCampaignsPerformanceTableConfig = {
	reportParams: {
		reportName: 'adx',
		sortBy: 'wins',
		sortOrder: ReportSortOrder.Desc,
		submit: true,
	},
	columns: [
		new CampaignNameAdxColumnConfig({ visibility: ColumnVisibilityOptions.AlwaysVisible }),
		new CampaignIdAdxColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new AdvertiserNameAdxColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new AdvertiserIdAdxColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CampaignGroupNameAdxColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CampaignGroupIdAdxColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new DomainAdxColumnConfig({ visibility: ColumnVisibilityOptions.AlwaysHidden }),
		...generatePerformanceColumns(),
	],
	isGraphAvailable: true,
	tableName: 'domainCampaignsPerformance',
};
