import { observer } from 'mobx-react';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useOutletContext, useParams } from 'react-router-dom';
import { CampaignForm } from '../../../modules/components/forms/campaign/campaign.form';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { Disable } from '@monorepo/base/src/components/disable/disable';
import { Toggle } from '@monorepo/base/src/components/form/toggle/toggle';
import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { Spacer, SpacerX } from '@monorepo/base/src/components/spacer/spacer';
import { useCampaignGet } from '../../../modules/hooks/apis/use-campaign-get';
import { useCampaignInputOptions } from '../../../modules/hooks/apis/use-campaign-input-options';
import { useList } from '../../../modules/hooks/tools/use-list';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { CreativeModel } from '../../../modules/models/creative.model';
import styles from './duplicate-campaign.module.scss';
import { IAdminxStore } from '../../../modules/stores';
import { Statuses } from '../../../modules/enums/status';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { ErrorMsg } from '../../../modules/components/error-msg/error-msg';
import { IPerformanceOptionsSortBy, PerformancesColumnsSnakeCase, useReportsList } from '../../../modules/hooks/apis/use-reports-list';
import { Card } from '@monorepo/base/src/components/card/card';

export const DuplicateCampaign = observer(() => {
	const { campaignStore, creativeStore, modalsStore } = useStores<IAdminxStore>();
	const { setIsTableOfContents } = useOutletContext<{ setIsTableOfContents: React.Dispatch<React.SetStateAction<boolean>> }>();
	const campaignCrudStore = campaignStore.getCrud();
	const error = campaignCrudStore.getHttpError();
	const reportList = campaignStore.getListStore();

	const { dispatchLog } = useLogs();
	useCampaignInputOptions();
	useReportsList(reportList, {
		groupBys: [PerformancesColumnsSnakeCase.Id, PerformancesColumnsSnakeCase.Name],
		reportName: 'adxCampaigns',
		sortBy: IPerformanceOptionsSortBy.Id,
	});
	useList<CreativeModel>(creativeStore.getListStore());

	const navigate = useNavigateParams();
	const location = useLocation();
	const params = useParams();

	useEffect(() => {
		setIsTableOfContents(true);
		campaignCrudStore.reset();
		campaignCrudStore.setIsLoading(true);
	}, [location]);

	const [isFormClicked, setFormClicked] = useState<boolean>(false);

	if (params.campaignId) {
		useCampaignGet({ id: params.campaignId });
		// useGet<CampaignModel>(campaignCrudStore, params.campaignId);
	}

	const campaign = campaignCrudStore.getData();
	const name = campaign.getName();
	const isLoading = campaignCrudStore.getIsLoading();
	const formStore = campaignCrudStore.getFormStore();
	const isValidForm = formStore.getIsValid();
	const isSuccess = campaignCrudStore.getIsSuccess();
	const httpError = campaignCrudStore.getHttpError();
	const targeting = campaign.getTargeting();

	useEffect(() => {
		if (!isValidForm && isFormClicked) {
			dispatchLog({
				msg: 'Please complete all mandatory fields',
				type: DispatchLogsTypes.Error,
			});
		}
	}, [isFormClicked]);

	useEffect(() => {
		if (isSuccess) {
			navigate(-1);
			dispatchLog({
				msg: `Campaign ${name} was successfully duplicated`,
				type: DispatchLogsTypes.Success,
			});
			campaignCrudStore.setIsSuccess(false);
		} else if (httpError) {
			dispatchLog({
				msg: httpError.getMessage(),
				type: DispatchLogsTypes.Error,
			});
		}
	}, [isSuccess]);

	const onDuplicateCampaign = () => {
		setFormClicked(true);
		if (targeting?.getIsSharedTargetingModified()) {
			modalsStore.addModal({
				children: (
					<ActionModal
						type={ModalTypes.Warning}
						primaryBtnTitle={'Yes, I am sure'}
						onAction={() => {
							campaignStore.getCrud().edit();
						}}>
						<Fragment>Caution: Altering shared targeting impacts all associated campaigns, are you sure?</Fragment>
					</ActionModal>
				),
			});
		} else {
			campaignStore.getCrud().create();
		}
	};

	return (
		<Fragment>
			<Disable is={isLoading}>
				<Page>
					<Pagebar>
						<PagebarTitle unstyled={true}>
							<div className={styles.titleWrapper}>
								<div className={styles.title}>Duplicate Campaign</div>
								<div className={styles.campaignStatus}>
									<span>{isLoading ? '...' : name}</span>
									<SpacerX />
									<Toggle
										label={campaign.getStatus() === Statuses.Active ? Statuses.Active : Statuses.Paused}
										onChange={status => campaign.setStatus(status ? Statuses.Active : Statuses.Paused)}
										defaultValue={campaign.getStatus() === Statuses.Active}
									/>
								</div>
							</div>
						</PagebarTitle>
					</Pagebar>
					<Card title="Deep Clone Options">
						<Spacer />
						<Toggle
							label={'Clone Sites In Target'}
							onChange={status => {
								campaign.deep_clone_campaign.clone_sites_in_target = status;
							}}
							defaultValue={campaign.deep_clone_campaign.clone_sites_in_target}
						/>
						<Spacer />
						<Toggle
							label={'Clone Bid Keys'}
							onChange={status => {
								campaign.deep_clone_campaign.clone_bid_keys = status;
							}}
							defaultValue={campaign.deep_clone_campaign.clone_bid_keys}
						/>
						<Spacer />
						<Toggle
							label={'Clone Trashed Bid Keys'}
							onChange={status => {
								campaign.deep_clone_campaign.clone_trash_keys = status;
							}}
							defaultValue={campaign.deep_clone_campaign.clone_trash_keys}
						/>
						<Spacer />
						<Toggle
							label={'Clone Models Params'}
							onChange={status => {
								campaign.deep_clone_campaign.clone_models_params = status;
							}}
							defaultValue={campaign.deep_clone_campaign.clone_models_params}
						/>
					</Card>
					<Spacer />

					<CampaignForm isDuplicate={true} />
				</Page>
			</Disable>
			<BottomBar>
				{error ? <ErrorMsg error={error} /> : null}
				<BottomBarActions>
					<PrimaryButton
						onClick={() => onDuplicateCampaign()}
						isRotateIcon={isLoading}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'duplicate_campaign_button')] }}
						icon={isLoading ? 'loading-01' : 'send-03'}>
						Duplicate
					</PrimaryButton>
				</BottomBarActions>
			</BottomBar>
		</Fragment>
	);
});
