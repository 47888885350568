export const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 });
export const currencyFormatterWithDecimals = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	maximumFractionDigits: 2,
});
export const numberFormatter = new Intl.NumberFormat('en', { notation: 'compact', maximumSignificantDigits: 10 });

export const numberFormatterWithCommas = new Intl.NumberFormat('en');

export const numberFormatterWithDecimals = new Intl.NumberFormat('en', { maximumFractionDigits: 2 });

export const numberFormatterWithDecimalsCompacted = new Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 2 });

export const currencyFormat = (
	value?: number,
	currencyWithDecimal?: boolean,
	options?: { digitLen?: number; decimalLen?: number }
): string | null => {
	if (value === undefined || value === null) {
		return null;
	}
	if (options?.digitLen || options?.decimalLen) {
		return new Intl.NumberFormat('en', {
			style: 'currency',
			currency: 'USD',
			notation: 'compact',
			maximumFractionDigits: options?.digitLen,
			maximumSignificantDigits: options.decimalLen,
		}).format(value);
	}
	return currencyWithDecimal ? currencyFormatterWithDecimals.format(value) : currencyFormatter.format(value);
};

export const percents = (value?: number): string | null => {
	if (!value) {
		return '0%';
	}
	return `${Math.floor(value * 10000) / 100 || 0}%`;
};

export const number = (value?: number, decimal?: boolean, compact = true, commas = false): string | null => {
	if (decimal) {
		if (compact) {
			return value !== null && value !== undefined ? numberFormatterWithDecimalsCompacted.format(value) : null;
		} else {
			return value !== null && value !== undefined ? numberFormatterWithDecimals.format(value) : null;
		}
	}

	if (commas) {
		return value !== null && value !== undefined ? numberFormatterWithCommas.format(value) : null;
	}

	//TODO remove this .replace later and make it work only with Intl.NumberFormat
	return value !== null && value !== undefined ? numberFormatter.format(value).replace(/\.(\d)+/, '') : null;
};

export const randomNumber = (min: number, max: number, places = 2): string => {
	const value = Math.random() * (max - min) + min;
	return Number.parseFloat(`${value}`).toFixed(places);
};

export const isStringInfinityOrNaN = (val: string | number) => {
	if (val === 'NaN' || val === 'Infinity') {
		return true;
	}

	return false;
};

export enum NumberActions {
	Equals = 'equal to',
	LessThan = 'less than',
	LessThanEquals = 'less than or equal to',
	GreaterThan = 'greater than',
	GreaterThanEquals = 'greater than or equal to',
}

export enum RangeActions {
	// InRange = 'in range',
	// OutRange = 'out of range',
	GreaterThanRange = 'greater than or equal to',
	LessThanRange = 'less than or equal to',
}

export type numberAction =
	| NumberActions.Equals
	| NumberActions.LessThan
	| NumberActions.LessThanEquals
	| NumberActions.GreaterThan
	| NumberActions.GreaterThanEquals;

export type rangeAction = RangeActions.GreaterThanRange | RangeActions.LessThanRange; //RangeActions.InRange | RangeActions.OutRange |

export const numberFuncs = {
	[NumberActions.Equals]: (number: number, filterValue: number) => number == filterValue,
	[NumberActions.LessThan]: (number: number, filterValue: number) => number < filterValue,
	[NumberActions.LessThanEquals]: (number: number, filterValue: number) => number <= filterValue,
	[NumberActions.GreaterThan]: (number: number, filterValue: number) => number > filterValue,
	[NumberActions.GreaterThanEquals]: (number: number, filterValue: number) => number >= filterValue,
};

export const rangeFuncs = {
	// [RangeActions.InRange]: (range: { max: number; min: number }, filterValue: number) => {
	// 	return range.min <= filterValue && filterValue <= range.max;
	// },
	// [RangeActions.OutRange]: (range: { max: number; min: number }, filterValue: number) => {
	// 	return range.min > filterValue || filterValue > range.max;
	// },
	[RangeActions.GreaterThanRange]: (range: { max: number; min: number }, filterValue: number) => {
		return range.min >= filterValue;
	},
	[RangeActions.LessThanRange]: (range: { max: number; min: number }, filterValue: number) => {
		return range.min <= filterValue;
	},
};
