import { Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { ReportFilterType } from '../../../../modules/components/report-table/stores/data-stores/old-report.types';
import ReportTable from '../../../../modules/components/report-table/table-component';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../../modules/stores';
import { Card } from '@monorepo/base/src/components/card/card';
import { useMetrics } from '../../../../modules/hooks/tools/use-metrics';
import { Chart } from '@monorepo/base/src/components/chart/chart';
import { availablePerformanceToMetrics } from '../../../../modules/models/performance.model';
import { useTableStore } from '../../../../modules/components/report-table/stores/use-table-store.hook';

export const DomainsCampaignsPage = observer(() => {
	const { domainsStore } = useStores<IAdminxStore>();
	const { domainName } = useParams();
	const { campaignsPerformance } = domainsStore;

	const hiddenFilters = domainName
		? [{ column: 'domain', filterType: ReportFilterType.EQUALS, value: [domainName], inverted: false }]
		: undefined;

	useTableStore({ reportTableStore: campaignsPerformance, hiddenFilters });

	const { metrics, xLabels } = useMetrics(campaignsPerformance.dataStore.graph?.getData() ?? null);

	return (
		<div>
			<Pagebar>
				<PagebarTitle>Campaigns</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>
			<Card full>
				<Chart
					isLoading={campaignsPerformance.dataStore.graph?.getIsLoading()}
					metrics={metrics}
					labels={xLabels}
					legendOptions={Object.keys(availablePerformanceToMetrics)}
				/>
			</Card>
			<ReportTable tableStore={campaignsPerformance} />
		</div>
	);
});
