import { observer } from 'mobx-react';
import { FC } from 'react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';

import { Chart } from '@monorepo/base/src/components/chart/chart';
import { Page, Pagebar, PagebarTitle, PagebarActions } from '@monorepo/base/src/components/page/page';
import { IAdminxStore } from '../../../modules/stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Card } from '@monorepo/base/src/components/card/card';
import ReportTable from '../../../modules/components/report-table/table-component';
import { CampaignRowActions } from '../../../modules/components/report-table/actions/campaign.action';
import { TableActionsBar } from '../../../modules/components/report-table/table-elements/table-actions';
import { TableDefaultButton } from '../../../modules/components/report-table/table-elements/table-buttons';
import routes from '../../../modules/routes/index.routes';
import { useTableStore } from '../../../modules/components/report-table/stores/use-table-store.hook';
import { useTableStoreMetrics } from '../../../modules/hooks/tools/use-repr-metrics';

const pageBarDebugProps = { dataAttrs: [new DataAttribute('id', 'pagebar')] };

export const Campaigns: FC = observer(() => {
	const { campaignStore } = useStores<IAdminxStore>();
	const { campaignsReportTable } = campaignStore;

	useTableStore({ reportTableStore: campaignsReportTable });

	const chartProps = useTableStoreMetrics(campaignsReportTable);

	return (
		<Page>
			<Pagebar debugProps={pageBarDebugProps}>
				<PagebarTitle>Campaigns</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>
			<Card full>
				<Chart isLoading={campaignsReportTable.dataStore.getIsLoading()} {...chartProps} />
			</Card>
			<ReportTable tableStore={campaignsReportTable}>
				<TableDefaultButton to={routes.campaigns.create()} />
				<TableActionsBar>
					<CampaignRowActions />
				</TableActionsBar>
			</ReportTable>
		</Page>
	);
});
