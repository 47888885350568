import { observer } from 'mobx-react';
import { FC } from 'react';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { Page, Pagebar, PagebarTitle, PagebarActions } from '@monorepo/base/src/components/page/page';
import { Outlet } from 'react-router-dom';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../modules/stores';
import ReportTable from '../../../modules/components/report-table/table-component';
import { useTableStore } from '../../../modules/components/report-table/stores/use-table-store.hook';
export const Domains: FC = observer(() => {
	const { domainsStore } = useStores<IAdminxStore>();

	const { domainsReport } = domainsStore;

	useTableStore({ reportTableStore: domainsReport });

	return (
		<Page>
			<Pagebar>
				<PagebarTitle>Domains</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>
			<ReportTable tableStore={domainsReport} />
			<Outlet />
		</Page>
	);
});
