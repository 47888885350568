import { CampaignsApi } from '../../apis/campaigns.api';
import { CampaignModel, ICampaignCreateForm, ICampaignEditForm, ICampaignTargetingListing } from '../../models/campaign.model';
import { runInAction } from 'mobx';
import { constantCase } from 'change-case';
import { DealType, DealTypes } from '../../enums/deal-types';
import { CampaignTypes } from '../../enums/campaign-types';
import { TargetingModel } from '../../models/targeting.model';
import { RulesModel } from '../../models/rules.model';
import { DailyBudgetModel } from '../../models/daily-budget.model';
import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { CreativeSelectionModes } from '../../enums/creative-selection-modes';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { HttpError } from '../../models/http-error.model';
import { whatIsWrongWithMyUrl } from '@monorepo/tools/src/lib/utils/url';
import { Statuses, Status } from '../../enums/status';
import { WhiteBlackLists } from '../../enums/white-black-list-enum';

const defaultCacheKeyGenerator = 'TagKeyGenerator';

const getWhiteBlackListDomainsPagesError = (params: {
	whitelistDomainsPages?: Set<string>;
	blacklistDomainsPages?: Set<string>;
}): FormError | null => {
	const { whitelistDomainsPages, blacklistDomainsPages } = params;
	let property: string;
	let list: Set<string>;
	if (whitelistDomainsPages?.size && blacklistDomainsPages?.size) {
		return new FormError('black_listed_sites', "Can't be both, whitelisting and blacklisting");
	} else if (blacklistDomainsPages?.size) {
		list = blacklistDomainsPages;
		property = 'black_listed_sites';
	} else if (whitelistDomainsPages?.size) {
		list = whitelistDomainsPages;
		property = 'white_listed_sites';
	} else {
		return null;
	}
	const [domains, pages] = Array.from(list).reduce(
		(acc, item) => {
			if (item.startsWith('http')) {
				acc[1].push(item);
			} else {
				acc[0].push(item);
			}
			return acc;
		},
		[[], []] as [string[], string[]]
	);

	const invalidDomains: string[] = [];
	for (const domain of domains) {
		const { error } = whatIsWrongWithMyUrl(`https://${domain}/`);
		if (error) {
			invalidDomains.push(`${domain} => ${error}`);
		}
	}
	const invalidPages: string[] = [];
	for (const page of pages) {
		const { error } = whatIsWrongWithMyUrl(page, { allowHttp: true });
		if (error) {
			invalidPages.push(`${page} => ${error}`);
		}
	}
	if (invalidDomains.length || invalidPages.length) {
		const errors: string[] = [];
		if (invalidDomains.length) {
			errors.push(`Invalid domains: ${invalidDomains.map(item => `"${item}"`).join(', ')}`);
		}
		if (invalidPages.length) {
			errors.push(`Invalid pages: ${invalidPages.map(item => `"${item}"`).join(', ')}`);
		}
		return new FormError(property, errors.join('; '));
	}
	return null;
};

const getWhiteBlackListKeysError = (params: { whitelistKeys?: Set<string>; blacklistKeys?: Set<string> }): FormError | null => {
	const { whitelistKeys, blacklistKeys } = params;

	if (blacklistKeys?.size && whitelistKeys?.size) {
		return new FormError('black_listed_keys', "Can't be both, whitelisting and blacklisting");
	}

	return null;
};

export class CampaignCrudStore extends BaseCrudStore<CampaignModel, ICampaignCreateForm, ICampaignEditForm, HttpError> {
	isCampaignEdited = false;
	isTargetListingsEdited = false;
	isCreativeListEdited = false;

	constructor() {
		super({
			apiLayer: CampaignsApi,
			model: CampaignModel,
			errorModel: HttpError,
		});
	}

	createInstance() {
		return new CampaignModel({
			targeting: new TargetingModel(),
			rules: new RulesModel(),
			daily_budget: new DailyBudgetModel(),
			status: Statuses.Paused,
			cache_key_generator: defaultCacheKeyGenerator,
			campaign_type: CampaignTypes.CPA,
			creative_selection_mode: CreativeSelectionModes.Off,
		});
	}

	async edit() {
		let error: HttpError | null = null;
		const isValid = this.isValid();
		if (!isValid) {
			return;
		}

		const campaignId = this.getData()?.getId();
		if (!campaignId) {
			runInAction(() => {
				this.setIsSuccess(false);
				// this.setHttpError(new Error('No campaign id'));
			});
			return;
		}

		this.setIsLoading(true);
		// edit campaign details
		await this.apiLayer.edit(campaignId.toString(), this.getEditFormData()).catch(err => {
			console.error(err);
			error = new HttpError({ message: 'Failed to save campaign', name: 'edit campaign', httpStatus: 400, errorPayload: err });
		});

		// make sure the normal edit success before try to edit the target listings
		if (!error && this.isTargetListingsEdited) {
			await CampaignsApi.editTargetListings(campaignId.toString(), this.getEditTargetListing()).catch(err => {
				console.error(err);
				error = new HttpError({
					message: 'Failed to save the target listings only!',
					name: 'edit campaign',
					httpStatus: 400,
					errorPayload: err,
				});
			});
		}

		const creativesIds = this.getCreativesIds();

		if (!error && this.isCreativeListEdited) {
			await CampaignsApi.editCreatives(campaignId, creativesIds || []);
		}

		const dealType = this.getData().getDealType();

		if (dealType === DealTypes.PreferredDeal) {
			const preferredDealIds = this.getPreferredDealIds();

			await CampaignsApi.attachDeals(campaignId, preferredDealIds);
		}

		if (error) {
			runInAction(() => {
				this.setHttpError(error);
				this.setIsLoading(false);
				this.setIsSuccess(false);
			});
		} else {
			runInAction(() => {
				this.setHttpError(error);
				this.setIsLoading(false);
				this.setIsSuccess(true);
			});
		}
	}

	async create() {
		const isValid = this.isValid();
		if (!isValid) {
			return;
		}
		this.setIsLoading(true);
		this.setIsSuccess(false);
		try {
			const createRes = await this.apiLayer.create(this.getCreateFormData());
			const creativesIds = this.getCreativesIds();
			const dealType = this.getData().getDealType();

			if (creativesIds.length > 0) {
				if (createRes.id) {
					await CampaignsApi.addCreatives(createRes.id, creativesIds);
				}
			}

			if (dealType === DealTypes.PreferredDeal) {
				const preferredDealIds = this.getPreferredDealIds();
				if (createRes.id) {
					await CampaignsApi.attachDeals(createRes.id, preferredDealIds);
				}
			}

			this.setIsLoading(false);
			this.setIsSuccess(true);
		} catch (error: any) {
			if (error && error?.data) {
				this.setHttpError(new HttpError(error.data));
			}
			this.setIsLoading(false);
			this.setIsSuccess(false);
		}
	}

	enableCampaigns(campaignsIds: id[]): Promise<void> {
		return CampaignsApi.enableCampaigns(campaignsIds)
			.then(() => {
				this.setIsLoading(false);
				this.setIsSuccess(true);
			})
			.catch(() => {
				this.setIsLoading(false);
				this.setIsSuccess(false);
			});
	}

	pauseCampaigns(campaignsIds: id[]): Promise<void> {
		return CampaignsApi.pauseCampaigns(campaignsIds)
			.then(() => {
				this.setIsLoading(false);
				this.setIsSuccess(true);
			})
			.catch(() => {
				this.setIsLoading(false);
				this.setIsSuccess(false);
			});
	}

	/**
	 * Must call isValidCampaign before calling this function
	 * @returns
	 */
	public getCreateFormData(): ICampaignCreateForm & ICampaignTargetingListing {
		const campaignGroup = this.getData()?.getCampaignGroup();
		const advertiser = this.getData()?.getAdvertiser();
		const advertiserId = advertiser?.getId();
		const status = this.getData()?.getStatus();
		const dealType = this.getData()?.getDealType();
		const strategy = this.getData()?.getStrategy();
		const parent = this.getData().getParentCampaign();
		const parentId = parent?.getId();
		const defaultPayout = this.getData().getDefaultPayout();
		const account = this.getData().getAccount();
		const deepClone = this.getData().deep_clone_campaign;
		if (parentId && deepClone) {
			deepClone.source_campaign_id = parentId;
		}

		if (this.getData().getBannerListType() === WhiteBlackLists.Blacklist) {
			this.getData().setWhitelistedSizes([]);
		} else {
			this.getData().setBlacklistedSizes([]);
		}

		return {
			name: this.getData().getName(),
			campaign_group: campaignGroup ? { id: campaignGroup.getId() } : null,
			advertiser: advertiserId ? { id: advertiserId } : undefined,
			status: status ? (constantCase(status) as Status) : undefined,
			parent: parentId ? { id: parentId } : undefined,
			priority: this.getData().getPriority(),
			secondary_priority: this.getData().getSecondaryPriority(),
			cache_key_generator: this.getData().getCacheKeyGenerator(),
			campaign_type: this.getData().getCampaignType(),
			deal_type: dealType ? (constantCase(dealType) as DealType) : undefined,
			billing_id: this.getData().getBillingId(),
			strategy: strategy ? { id: strategy.getId() } : null,
			dest_url: this.getData().getDestinationUrl(),
			targeting: this.getData().getTargeting()?.getFormData(),
			daily_budget: this.getData().getDailyBudget()?.getFormData(),
			default_payout: defaultPayout,
			black_listed_sizes: this.getData().getBlacklistedSizes(),
			white_listed_sizes: this.getData().getWhitelistedSizes(),
			daily_domain_max_loss: this.getData().getDailyDomainMaxLoss(),
			daily_max_sales: this.getData().getDailyMaxSales(),
			account: account ? account : undefined,

			rules: this.getData().getRules()?.getFormData(),
			// creatives,
			creative_selection_mode: this.getData().getCreativeSelectionMode(),
			deep_clone_campaign: deepClone,
			...this.getEditTargetListing(),
		};
	}

	/**
	 * Must call isValidCampaign before calling this function
	 * @returns
	 */
	public getEditFormData(): ICampaignEditForm {
		const campaignGroup = this.getData()?.getCampaignGroup();
		const advertiser = this.getData()?.getAdvertiser();
		const status = this.getData()?.getStatus();
		const dealType = this.getData()?.getDealType();
		const strategy = this.getData()?.getStrategy();
		const parent = this.getData().getParentCampaign();
		const parentId = parent?.getId();
		const defaultPayout = this.getData().getDefaultPayout();
		const account = this.getData().getAccount();

		if (this.getData().getBannerListType() === WhiteBlackLists.Blacklist) {
			this.getData().setWhitelistedSizes([]);
		} else {
			this.getData().setBlacklistedSizes([]);
		}

		return {
			id: this.getData().getId(),
			name: this.getData().getName(),
			account: account ? account : undefined,
			campaign_group: campaignGroup ? { id: campaignGroup.getId() } : null,
			advertiser: advertiser ? { id: advertiser.getId() } : undefined,
			status: status ? (constantCase(status) as Status) : undefined,
			parent: parentId ? { id: parentId } : undefined,
			priority: this.getData().getPriority(),
			secondary_priority: this.getData().getSecondaryPriority(),
			cache_key_generator: this.getData().getCacheKeyGenerator(),
			campaign_type: this.getData().getCampaignType(),
			deal_type: dealType ? (constantCase(dealType) as DealType) : undefined,
			billing_id: this.getData().getBillingId(),
			strategy: strategy ? { id: strategy.getId() } : null,
			dest_url: this.getData().getDestinationUrl(),
			targeting: this.getData().getTargeting()?.getFormData(),
			daily_budget: this.getData().getDailyBudget()?.getFormData(),
			default_payout: defaultPayout,
			rules: this.getData().getRules()?.getFormData(),
			creative_selection_mode: this.getData().getCreativeSelectionMode(),
			black_listed_sizes: this.getData().getBlacklistedSizes(),
			white_listed_sizes: this.getData().getWhitelistedSizes(),
			daily_domain_max_loss: this.getData().getDailyDomainMaxLoss(),
			daily_max_sales: this.getData().getDailyMaxSales(),
		};
	}

	public getEditTargetListing(): ICampaignTargetingListing {
		return {
			black_listed_sites: this.getData()?.getBlackListedSites() ? Array.from(this.getData()?.getBlackListedSites() || []) : undefined,
			white_listed_sites: this.getData()?.getWhiteListedSites() ? Array.from(this.getData()?.getWhiteListedSites() || []) : undefined,

			black_listed_keys: this.getData()?.getBlacklistedKeys() ? Array.from(this.getData()?.getBlacklistedKeys() || []) : undefined,
			white_listed_keys: this.getData()?.getWhitelistedKeys() ? Array.from(this.getData()?.getWhitelistedKeys() || []) : undefined,
		};
	}

	public isValid(): boolean {
		this.formStore.reset();

		const campaignName = this.getData().getName();
		if (!campaignName) {
			this.formStore.addError(new FormError('name', 'Please provide a campaign name'));
		}

		if (campaignName && !this.getData().getName()) {
			this.formStore.addError(new FormError('name', 'Please provide a campaign name with lower case letters and no spaces'));
		}

		const destUrl = this.getData().getDestinationUrl();
		if (!destUrl) {
			this.formStore.addError(new FormError('dest_url', 'Please provide a destination url'));
		}

		// if (destUrl && !isValidUrl(destUrl)) {
		// 	this.formStore.addError(new FormError('dest_url', 'Please provide a valid url'));
		// }

		if (!this.getData().getAdvertiser()) {
			this.formStore.addError(new FormError('advertiser', 'Please provide an advertiser'));
		}

		if (!this.getData().getCampaignGroup()) {
			this.formStore.addError(new FormError('campaign_group', 'Please provide a campaign group'));
		}

		if (!this.getData().getCacheKeyGenerator()) {
			this.formStore.addError(new FormError('cache_key_generator', 'Please provide cache key generator'));
		}

		if (!this.getData().getCampaignType()) {
			this.formStore.addError(new FormError('campaign_type', 'Please provide campaign type'));
		}

		if (this.getData().getDealType() === DealTypes.OpenAuction && !this.getData().getBillingId()) {
			this.formStore.addError(new FormError('billing_id', 'Please provide a billing id'));
		}

		if (!this.getData().getDealType() && !this.getData().getBillingId()) {
			this.formStore.addError(new FormError('billing_id', 'Please provide a billing id'));
		}

		if (!this.getData().getStrategy()) {
			this.formStore.addError(new FormError('strategy', 'Please provide a strategy'));
		}

		if (!this.getData().getTargeting()?.getEnvironment()) {
			this.formStore.addError(new FormError('environment', 'Please provide at least one environment'));
		}

		if (!this.getData().getTargeting()?.getDeviceTypes() || this.getData().getTargeting()?.getDeviceTypes()?.size === 0) {
			this.formStore.addError(new FormError('device_types', 'Please provide at least one device type'));
		}

		if (!this.getData().getTargeting()?.getSellerType()) {
			this.formStore.addError(new FormError('seller_type', 'Please provide a seller type'));
		}
		if (!this.getData().getTargeting()?.getInterstitial()) {
			this.formStore.addError(new FormError('interstitial', 'Please provide a interstitial'));
		}

		if (!this.getData().getTargeting()?.getOperatingSystems()) {
			this.formStore.addError(new FormError('operating_systems', 'Please provide at least one operating system'));
		}

		if (!this.getData().getTargeting()?.getBrowsers()) {
			this.formStore.addError(new FormError('browsers', 'Please provide at least one browser'));
		}

		if (!this.getData().getTargeting()?.getGeos() || this.getData().getTargeting()?.getGeos()?.length === 0) {
			this.formStore.addError(new FormError('geos', 'Please provide at least one country'));
		}
		const formErrors = this.formStore.getErrors();
		if (
			formErrors.has('device_types') &&
			formErrors.has('operating_systems') &&
			formErrors.has('browsers') &&
			formErrors.has('geos') &&
			!this.getData().getTargeting()?.getId()
		) {
			this.formStore.addError(new FormError('targeting_id', 'Please select a targeting'));
		}

		const account = this.getData().getAccount();
		if (!account) {
			this.formStore.addError(new FormError('account', 'Please provide an account'));
		}

		// validate domains and pages
		const whiteBlackListDomainsPagesError = getWhiteBlackListDomainsPagesError({
			whitelistDomainsPages: this.getData().getWhiteListedSites(),
			blacklistDomainsPages: this.getData().getBlackListedSites(),
		});
		if (whiteBlackListDomainsPagesError) {
			this.formStore.addError(whiteBlackListDomainsPagesError);
		}

		// validate keys
		const whiteBlackListKeysError = getWhiteBlackListKeysError({
			whitelistKeys: this.getData().getWhitelistedKeys(),
			blacklistKeys: this.getData().getBlacklistedKeys(),
		});
		if (whiteBlackListKeysError) {
			this.formStore.addError(whiteBlackListKeysError);
		}

		return this.formStore.getIsValid();
	}

	private getCreativesIds(): id[] {
		const creativeIds: id[] = [];
		this.getData()
			?.getCreatives()
			?.forEach(creative => {
				const creativeId = creative.getId();
				if (creativeId) {
					creativeIds.push(creativeId);
				}
			});
		return creativeIds;
	}

	private getPreferredDealIds(): id[] {
		const preferredDealIds: id[] = [];
		this.getData()
			?.getPreferredDeals()
			?.forEach(campaignPreferredDeal => {
				const preferredDealId = campaignPreferredDeal.getId();
				if (preferredDealId) {
					preferredDealIds.push(preferredDealId);
				}
			});
		return preferredDealIds;
	}

	public async getTargetsList(): Promise<TargetingModel[]> {
		return (await CampaignsApi.getTargets()) || [];
	}

	public async getAmountOfSharedTarget(id: id): Promise<CampaignModel[]> {
		return (await CampaignsApi.getAmountOfSharedTarget(id)) || 0;
	}

	public initEditedState() {
		this.isCreativeListEdited = false;
		this.isTargetListingsEdited = false;
	}

	public setCreativeListEdited() {
		this.isCreativeListEdited = true;
	}

	public setTargetListingsEdited() {
		this.isTargetListingsEdited = true;
	}
}
