import { DomainAdxColumnConfig } from '../../../columns/old-adx-report.columns';
import { generatePerformanceColumns } from '../../../columns/metrics.columns';
import { ReportSortOrder } from '../../../stores/data-stores/old-report.types';
import { ColumnVisibilityOptions } from '../../../columns/columns.types';

export const domainsPerformanceTableConfig = {
	reportParams: {
		reportName: 'adx',
		sortBy: 'wins',
		sortOrder: ReportSortOrder.Desc,
	},
	columns: [
		new DomainAdxColumnConfig({ visibility: ColumnVisibilityOptions.AlwaysVisible, type: 'data' }),
		...generatePerformanceColumns(),
	],
	isGraphAvailable: false,
	tableName: 'domainsReport',
};
