import { makeAutoObservable } from 'mobx';
import { Status } from '../../enums/status';
import { id } from '@monorepo/tools/src/lib/types/primitives';

export interface IBidderCampaignTargetingLink {
	id?: id;
	status?: Status;
	dest_link?: string;
	link_group?: string;
	yid?: string;
	encode_url?: boolean;
	cids?: IBidderCampaignTargetingLinkCid[];
	weight?: number; // only when targeting policy is "byWeight"
	pid?: id; // only when targeting policy is "perPublisher"
	max_conversions?: number; // only when targeting policy is "perPublisher" or "withConversionLimit"
}

export class BidderCampaignTargetingLinkModel implements IBidderCampaignTargetingLink {
	private _id?: id;
	private _status?: Status;
	private _dest_link?: string;
	private _link_group?: string;
	private _yid?: string;
	private _encode_url?: boolean;
	private _cids?: BidderCampaignTargetingLinkCidModel[];
	private _weight?: number;
	private _pid?: id;
	private _max_conversions?: number;

	constructor(data: IBidderCampaignTargetingLink) {
		this._id = data.id;
		this._status = data.status;
		this._dest_link = data.dest_link;
		this._link_group = data.link_group;
		this._yid = data.yid;
		this._encode_url = data.encode_url;
		this._cids = data.cids?.map(cid => new BidderCampaignTargetingLinkCidModel(cid)) || [];
		this._weight = data.weight;
		this._pid = data.pid;
		this._max_conversions = data.max_conversions;

		makeAutoObservable(this);
	}

	get id(): id | undefined {
		return this._id;
	}

	set id(value: id | undefined) {
		this._id = value;
	}

	get status(): Status | undefined {
		return this._status;
	}

	set status(value: Status | undefined) {
		this._status = value;
	}

	get dest_link(): string | undefined {
		return this._dest_link;
	}

	set dest_link(value: string | undefined) {
		this._dest_link = value;
	}

	get link_group(): string | undefined {
		return this._link_group;
	}

	set link_group(value: string | undefined) {
		this._link_group = value;
	}

	get yid(): string | undefined {
		return this._yid;
	}

	set yid(value: string | undefined) {
		this._yid = value;
	}

	get encode_url(): boolean | undefined {
		return this._encode_url;
	}

	set encode_url(value: boolean | undefined) {
		this._encode_url = value;
	}

	get cids(): BidderCampaignTargetingLinkCidModel[] | undefined {
		return this._cids;
	}

	set cids(value: IBidderCampaignTargetingLinkCid[] | undefined) {
		this._cids = value?.map(cid => new BidderCampaignTargetingLinkCidModel(cid));
	}

	get weight(): number | undefined {
		return this._weight;
	}

	set weight(value: number | undefined) {
		this._weight = value;
	}

	get pid(): id | undefined {
		return this._pid;
	}

	set pid(value: id | undefined) {
		this._pid = value;
	}

	get max_conversions(): number | undefined {
		return this._max_conversions;
	}

	set max_conversions(value: number | undefined) {
		this._max_conversions = value;
	}

	addNewCid = () => {
		const cidModel = new BidderCampaignTargetingLinkCidModel({ id: 0, cid: '', weight: 0 });
		this._cids = [...(this._cids || []), cidModel];
	};

	toObject(): IBidderCampaignTargetingLink {
		return {
			id: this._id,
			status: this._status,
			dest_link: this._dest_link,
			link_group: this._link_group,
			yid: this._yid,
			encode_url: this._encode_url,
			cids: this._cids?.map(cid => cid.toObject()) ?? [],
			weight: this._weight,
			pid: this._pid,
			max_conversions: this._max_conversions,
		};
	}
}

export interface IBidderCampaignTargetingLinkCid {
	id: id;
	weight: number;
	cid: string;
}

export class BidderCampaignTargetingLinkCidModel implements IBidderCampaignTargetingLinkCid {
	private _id: id;
	private _weight: number;
	private _cid: string;

	constructor(data: IBidderCampaignTargetingLinkCid) {
		this._id = data.id;
		this._weight = data.weight;
		this._cid = data.cid;

		makeAutoObservable(this);
	}

	get id() {
		return this._id;
	}

	set id(value: id) {
		this._id = value;
	}

	get weight() {
		return this._weight;
	}

	set weight(value: number) {
		this._weight = value;
	}

	get cid() {
		return this._cid;
	}

	set cid(value: string) {
		this._cid = value;
	}

	toObject() {
		return {
			id: this.id,
			weight: this.weight,
			cid: this.cid,
		};
	}
}
