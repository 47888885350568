import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import { CellContext } from '@tanstack/react-table';
import routes from '../../../routes/index.routes';
import { mapToTagStatus, Status } from '../../../enums/status';
import { Tag } from '@monorepo/base/src/components/tag/tag';
import { IDataType } from '../stores/data-stores/data-store.types';

export const campaignNameCell = <T,>({ row }: CellContext<T, unknown>) => {
	return (
		<TableLink toEdit={routes.campaigns.edit(row.getValue('campaign_id'))} to={routes.campaigns.view(row.getValue('campaign_id'))}>
			{row.getValue('campaign_name')}
		</TableLink>
	);
};

export const statusCellCreator = (column_name: string) => {
	return ({ row }: CellContext<IDataType, unknown>) => {
		const statusTmp = row.getValue(column_name) as string;
		const status = statusTmp?.trim().toLowerCase();
		return <Tag type={mapToTagStatus(status?.toUpperCase() as Status)}>{status}</Tag>;
	};
};

export const campaignGroupStatusCell = statusCellCreator('campaign_group_status');
export const campaignStatusCell = statusCellCreator('campaign_status');
export const advertiserStatusCell = statusCellCreator('advertiser_status');

export const advertiserNameCell = <T,>({ row }: CellContext<T, unknown>) => {
	return <TableLink to={routes.advertisers.view(row.getValue('advertiser_id'))}>{row.getValue('advertiser_name')}</TableLink>;
};

export const campaignGroupNameCell = <T,>({ row }: CellContext<T, unknown>) => {
	return <TableLink to={routes.campaignGroups.view(row.getValue('campaign_group_id'))}>{row.getValue('campaign_group_name')}</TableLink>;
};
