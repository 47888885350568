import ReportStore from '../stores/data-stores/old-report.store';
import { ReportFilterType } from '../stores/data-stores/old-report.types';
import { FilterType, IFilterDefinition } from './filters.types';

export interface IEntityIdFilterData {
	id: string;
	name: string;
}

export class FilterDefinition implements IFilterDefinition {
	type;
	actions;
	options;
	multi;
	selectFromDataSource;
	constructor(defintion: IFilterDefinition) {
		this.type = defintion.type;
		this.actions = defintion.actions;
		this.options = defintion.options;
		this.multi = defintion.multi;
		this.selectFromDataSource = defintion.selectFromDataSource;
	}
}

export class NumbersFilterDefinition extends FilterDefinition {
	constructor() {
		super({
			type: FilterType.Number,
			actions: [
				{ label: 'Greater', value: ReportFilterType.GREATER },
				{ label: 'Greater and Equals', value: ReportFilterType.GREATER_AND_EQUALS },
				{ label: 'Less', value: ReportFilterType.LESS },
				{ label: 'Less and Equals', value: ReportFilterType.LESS_AND_EQUALS },
				{ label: 'Equals', value: ReportFilterType.EQUALS },
				{ label: 'Not Equals', value: ReportFilterType.NOT_EQUALS },
			],
		});
	}
}

export class TextFilterDefinition extends FilterDefinition {
	constructor() {
		super({
			type: FilterType.Text,
			actions: [
				{ label: 'Include', value: ReportFilterType.INCLUDE },
				{ label: 'Contains', value: ReportFilterType.CONTAINS },
			],
		});
	}
}

export class CampaignEntityFilterDefinition extends FilterDefinition {
	constructor() {
		super({
			type: FilterType.SelectFromDataSource,
			actions: [{ label: 'Include', value: ReportFilterType.INCLUDE }],
			selectFromDataSource: {
				store: new ReportStore<IEntityIdFilterData>({
					reportParams: {
						reportName: 'adxCampaigns',
						groupBys: ['name', 'id'],
						sortBy: 'name',
					},
					isGraphAvailable: false,
				}),
				cb: row => {
					const rowAs = row as IEntityIdFilterData;
					return {
						value: rowAs.id,
						label: `${rowAs.name} (${rowAs.id})`,
					};
				},
			},
		});
	}
}

export class AdvertiserEntityFilterDefinition extends FilterDefinition {
	constructor() {
		super({
			type: FilterType.SelectFromDataSource,
			actions: [{ label: 'Include', value: ReportFilterType.INCLUDE }],
			selectFromDataSource: {
				store: new ReportStore<IEntityIdFilterData>({
					reportParams: {
						reportName: 'adxAdvertisers',
						groupBys: ['name', 'id'],
						sortBy: 'name',
					},
					isGraphAvailable: false,
				}),
				cb: row => {
					const rowAs = row as IEntityIdFilterData;
					return {
						value: rowAs.id,
						label: `${rowAs.name} (${rowAs.id})`,
					};
				},
			},
		});
	}
}

export class CampaignGroupEntityFilterDefinition extends FilterDefinition {
	constructor() {
		super({
			type: FilterType.SelectFromDataSource,
			actions: [{ label: 'Include', value: ReportFilterType.INCLUDE }],
			selectFromDataSource: {
				store: new ReportStore<IEntityIdFilterData>({
					reportParams: {
						reportName: 'adxCampaignGroup',
						groupBys: ['name', 'id'],
						sortBy: 'name',
					},
					isGraphAvailable: false,
				}),
				cb: row => {
					const rowAs = row as IEntityIdFilterData;
					return {
						value: rowAs.id,
						label: `${rowAs.name} (${rowAs.id})`,
					};
				},
			},
		});
	}
}
