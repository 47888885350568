import { Statuses } from '../../../../enums/status';
import { ReportFilterType } from '../../stores/data-stores/old-report.types';
import { FilterType, IFilterDefinition } from '../filters.types';

export class StatusFilterDefinition implements IFilterDefinition {
	type = FilterType.Select;
	actions = [
		{
			label: 'equals',
			value: ReportFilterType.EQUALS,
		},
		{
			label: 'not equals',
			value: ReportFilterType.NOT_EQUALS,
		},
	];
	options = [
		{ label: 'Active', value: Statuses.Active },
		{ label: 'Paused', value: Statuses.Paused },
		{ label: 'No Balance', value: Statuses.NoBalance },
		{ label: 'New', value: Statuses.New },
		{ label: 'Ended', value: Statuses.Ended },
	];
}
