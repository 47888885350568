import { ColumnConfig, MetricsColumnConfig, MetricsCurrencyColumnConfig, MetricsPercentageColumnConfig } from './columns';
import {
	currencyWithDecimalCellCreator,
	currencyWithDecimalValueFormatter,
	percentageRoundedCellCreator,
	percentageRoundedValueFormatter,
} from '../cells/metrics.cells';
import { ColumnVisibilityOptions } from './columns.types';

export class AuctionsColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'auctions', accessor: 'auctions', header: 'Auctions', ...params });
	}
}

export class WinsColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'wins', accessor: 'wins', header: 'Wins', ...params });
	}
}

export class ClicksColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'clicks', accessor: 'clicks', header: 'Clicks', ...params });
	}
}
export class ImpressionsColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'impressions', accessor: 'impressions', header: 'Impressions', ...params });
	}
}

export class ConversionsColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'conversions', accessor: 'conversions', header: 'Conversions', ...params });
	}
}

export class CostColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'cost', accessor: 'cost', header: 'Cost', ...params });
	}
}

export class ConversionValueColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'conversion_value', accessor: 'conversionValue', header: 'Revenue', ...params });
	}
}

export class WinRateColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'win_rate', accessor: 'winRate', header: 'Win rate', ...params });
	}
}

export class RoasColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'roas',
			accessor: 'roas',
			header: 'ROAS',
			graphFormatter: percentageRoundedValueFormatter,
			footerFormatter: percentageRoundedValueFormatter,
			cell: percentageRoundedCellCreator('roas'),
			...params,
		});
	}
}

export class CtrColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'ctr', accessor: 'ctr', header: 'CTR', ...params });
	}
}

export class CvrColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'cvr', accessor: 'Cvr', header: 'CVR', ...params });
	}
}

export class LpCvrColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'lp_cvr', accessor: 'lpCvr', header: 'CVR', ...params });
	}
}

export class CpcColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'cpc',
			accessor: 'cpc',
			header: 'CPC',
			cell: currencyWithDecimalCellCreator('cpc'),
			footerFormatter: currencyWithDecimalValueFormatter,
			graphFormatter: currencyWithDecimalValueFormatter,
			...params,
		});
	}
}

export class CpmColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'cpm',
			accessor: 'cpm',
			header: 'CPM',
			cell: currencyWithDecimalCellCreator('cpm'),
			footerFormatter: currencyWithDecimalValueFormatter,
			graphFormatter: currencyWithDecimalValueFormatter,
			...params,
		});
	}
}

export class GainColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'gain', accessor: 'gain', header: 'Gain', ...params });
	}
}

export class OptGainColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'opt_gain', accessor: 'optGain', header: 'Opt. Gain', ...params });
	}
}

export class OptCostColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'opt_cost', accessor: 'optCost', header: 'Opt. Cost', ...params });
	}
}

export class AvgBidFloorColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'avg_bid_floor', accessor: 'avgBidFloor', header: 'Avg. bid floor', ...params });
	}
}

export class AvgBidToWinColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'avg_bid_to_win', accessor: 'avgBidToWin', header: 'Avg. bid to win', ...params });
	}
}

export class MaxBidFloorColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'max_bid_floor', accessor: 'maxBidFloor', header: 'Max bid floor', ...params });
	}
}

export class MaxBidToWinColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'max_bid_to_win', accessor: 'maxBidToWin', header: 'Max bid to win', ...params });
	}
}

export class MinBidFloorColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({ name: 'min_bid_floor', accessor: 'minBidFloor', header: 'Min bid floor', ...params });
	}
}

export class CPAColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'cpa',
			accessor: 'cpa',
			header: 'CPA',
			cell: currencyWithDecimalCellCreator('cpa'),
			footerFormatter: currencyWithDecimalValueFormatter,
			graphFormatter: currencyWithDecimalValueFormatter,
			...params,
		});
	}
}

export class SpmColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'spm',
			accessor: 'spm',
			header: 'SPM',
			...params,
		});
	}
}

export class TotalMoneyEarnColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'total_money_earn',
			accessor: 'total_money_earn',
			header: 'Total Earn',
			...params,
		});
	}
}

export class TotalMoneySpentColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'total_money_spent',
			accessor: 'total_money_spent',
			header: 'Total Spent',
			...params,
		});
	}
}

export class DailyMoneySpentColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'daily_money_spent',
			accessor: 'daily_money_spent',
			header: 'Daily Spent',
			...params,
		});
	}
}

export class DailyMoneyEarnColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'daily_money_earn',
			accessor: 'daily_money_earn',
			header: 'Daily Earn',
			...params,
		});
	}
}

export class BillableWinRateColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'billable_win_rate',
			accessor: 'billable_win_rate',
			header: 'Billable Win Rate',
			...params,
		});
	}
}

export class ActualWinRateColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'actual_win_rate',
			accessor: 'actual_win_rate',
			header: 'Actual Win Rate',
			...params,
		});
	}
}

export class BidRateColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'bid_rate',
			accessor: 'bid_rate',
			header: 'Bid Rate',
			...params,
		});
	}
}

export class AvgCtrColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'avg_ctr',
			accessor: 'avg_ctr',
			header: 'Avg CTR',
			...params,
		});
	}
}

export class CalcRoasColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'calc_roas',
			accessor: 'calc_roas',
			header: 'Calc ROAS',
			...params,
		});
	}
}

export class ValidAuctionsRateColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'valid_auctions_rate',
			accessor: 'valid_auctions_rate',
			header: 'Valid Auctions Rate',
			...params,
		});
	}
}

export class AuctionsOutbiddedRateColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions_outbidded_rate',
			accessor: 'auctions_outbidded_rate',
			header: 'Auctions Outbidded Rate',
			...params,
		});
	}
}

export class AuctionsPublisherBlockedRateColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions_publisher_blocked_rate',
			accessor: 'auctions_publisher_blocked_rate',
			header: 'Auctions Publisher Blocked Rate',
			...params,
		});
	}
}

export class AuctionsBelowBidfloorRateColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions_below_bidfloor_rate',
			accessor: 'auctions_below_bidfloor_rate',
			header: 'Auctions Below Bidfloor Rate',
			...params,
		});
	}
}

export class AuctionsDealsOutbiddedRateColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions_deals_outbidded_rate',
			accessor: 'auctions_deals_outbidded_rate',
			header: 'Auctions Deals Outbidded Rate',
			...params,
		});
	}
}

export class AuctionsCreativeSizeIssuesRateColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions_creative_size_issues_rate',
			accessor: 'auctions_creative_size_issues_rate',
			header: 'Auctions Creative Size Issues Rate',
			...params,
		});
	}
}

export class AuctionsInvalidOthersRateColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auctions_invalid_others_rate',
			accessor: 'auctions_invalid_others_rate',
			header: 'Auctions Invalid Others Rate',
			...params,
		});
	}
}

export class FilterNoBidFoundRateColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_no_bid_found_rate',
			accessor: 'filter_no_bid_found_rate',
			header: 'Filter No Bid Found Rate',
			...params,
		});
	}
}

export class FilterBelowBidfloorRateColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_below_bidfloor_rate',
			accessor: 'filter_below_bidfloor_rate',
			header: 'Filter Below Bidfloor Rate',
			...params,
		});
	}
}

export class FilterTrashRateColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_trash_rate',
			accessor: 'filter_trash_rate',
			header: 'Filter Trash Rate',
			...params,
		});
	}
}

export class FilterUserRateColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_user_rate',
			accessor: 'filter_user_rate',
			header: 'Filter User Rate',
			...params,
		});
	}
}

export class FilterBudgetRateColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_budget_rate',
			accessor: 'filter_budget_rate',
			header: 'Filter Budget Rate',
			...params,
		});
	}
}

export class FilterCreativeRateColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_creative_rate',
			accessor: 'filter_creative_rate',
			header: 'Filter Creative Rate',
			...params,
		});
	}
}

export class FilterTargetingRateColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_targeting_rate',
			accessor: 'filter_targeting_rate',
			header: 'Filter Targeting Rate',
			...params,
		});
	}
}

export class FilterOthersRateColumnConfig extends MetricsPercentageColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'filter_others_rate',
			accessor: 'filter_others_rate',
			header: 'Filter Others Rate',
			...params,
		});
	}
}

export class RequestsAllColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'requests_all',
			accessor: 'requests_all',
			header: 'Requests All',
			...params,
		});
	}
}

export class RequestsOkColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'requests_ok',
			accessor: 'requests_ok',
			header: 'Requests Ok',
			...params,
		});
	}
}

export class RequestsOkButNotSelectedColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'requests_ok_but_not_selected',
			accessor: 'requests_ok_but_not_selected',
			header: 'Requests Ok - Not Selected',
			...params,
		});
	}
}

export class PotentialTagsColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'potential_tags',
			accessor: 'potential_tags',
			header: 'Potential Tags',
			...params,
		});
	}
}

export class BiddedTagsColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'bidded_tags',
			accessor: 'bidded_tags',
			header: 'Bidded Tags',
			...params,
		});
	}
}

export class PotentialFormatsColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'potential_formats',
			accessor: 'potential_formats',
			header: 'Potential Formats',
			...params,
		});
	}
}

export class FormatsAuctionedColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'formats_auctioned',
			accessor: 'formats_auctioned',
			header: 'Formats Auctioned',
			...params,
		});
	}
}

export class FormatsUsedColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'formats_used',
			accessor: 'formats_used',
			header: 'Formats Used',
			...params,
		});
	}
}

export class PotentialPublishersColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'potential_publishers',
			accessor: 'potential_publishers',
			header: 'Potential Publishers',
			...params,
		});
	}
}

export class PublishersAuctionedColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'publishers_auctioned',
			accessor: 'publishers_auctioned',
			header: 'Publishers Auctioned',
			...params,
		});
	}
}

export class PotentialPagesColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'potential_pages',
			accessor: 'potential_pages',
			header: 'Potential Pages',
			...params,
		});
	}
}

export class PagesAuctionedColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'pages_auctioned',
			accessor: 'pages_auctioned',
			header: 'Pages Auctioned',
			...params,
		});
	}
}

export class TagsAuctionedColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'tags_auctioned',
			accessor: 'tags_auctioned',
			header: 'Tags Auctioned',
			...params,
		});
	}
}

export class ClosedKeysColumnConfig extends MetricsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'closed_keys',
			accessor: 'closed_keys',
			header: 'Closed Keys',
			...params,
		});
	}
}

export const generatePerformanceColumns = (): ColumnConfig[] => {
	return [
		new AuctionsColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new WinsColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new ClicksColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new ConversionsColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CostColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new ConversionValueColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new SpmColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new RoasColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new WinRateColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CtrColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new LpCvrColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CpcColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CpmColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CPAColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new GainColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new OptGainColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new OptCostColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new AvgBidFloorColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new AvgBidToWinColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new MaxBidFloorColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new MaxBidToWinColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new MinBidFloorColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new ImpressionsColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
	];
};

export const generateAdxReprPerformanceColumns = (): ColumnConfig[] => {
	return [
		new WinsColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new AuctionsColumnConfig({ name: 'auctions_all', accessor: 'auctions_all', visibility: ColumnVisibilityOptions.Visible }),
		new ClicksColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CostColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new RoasColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new ConversionValueColumnConfig({ name: 'payout', accessor: 'payout', visibility: ColumnVisibilityOptions.Visible }),
		new ConversionsColumnConfig({ name: 'sales', accessor: 'sales', visibility: ColumnVisibilityOptions.Visible }),
		new SpmColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CtrColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new LpCvrColumnConfig({ accessor: 'lp_cvr', visibility: ColumnVisibilityOptions.Visible }),
		new CpcColumnConfig(),
		new CPAColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CpmColumnConfig(),
		new GainColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new OptGainColumnConfig({ accessor: 'opt_gain' }),
		new OptCostColumnConfig({ accessor: 'opt_cost' }),
		new AvgBidToWinColumnConfig({ accessor: 'avg_bid_to_win' }),
		new BillableWinRateColumnConfig(),
		new ActualWinRateColumnConfig(),
		new BidRateColumnConfig(),
		new AvgCtrColumnConfig(),
		new CalcRoasColumnConfig(),
		new ValidAuctionsRateColumnConfig(),
		new AuctionsOutbiddedRateColumnConfig(),
		new AuctionsPublisherBlockedRateColumnConfig(),
		new AuctionsBelowBidfloorRateColumnConfig(),
		new AuctionsDealsOutbiddedRateColumnConfig(),
		new AuctionsCreativeSizeIssuesRateColumnConfig(),
		new AuctionsInvalidOthersRateColumnConfig(),
		new FilterNoBidFoundRateColumnConfig(),
		new FilterBelowBidfloorRateColumnConfig(),
		new FilterTrashRateColumnConfig(),
		new FilterUserRateColumnConfig(),
		new FilterBudgetRateColumnConfig(),
		new FilterCreativeRateColumnConfig(),
		new FilterTargetingRateColumnConfig(),
		new FilterOthersRateColumnConfig(),
		new RequestsAllColumnConfig(),
		new RequestsOkColumnConfig(),
		new RequestsOkButNotSelectedColumnConfig(),
		new PotentialTagsColumnConfig(),
		new BiddedTagsColumnConfig(),
		new PotentialFormatsColumnConfig(),
		new FormatsAuctionedColumnConfig(),
		new FormatsUsedColumnConfig(),
		new PotentialPublishersColumnConfig(),
		new PublishersAuctionedColumnConfig(),
		new PotentialPagesColumnConfig(),
		new PagesAuctionedColumnConfig(),
		new TagsAuctionedColumnConfig(),
		new ClosedKeysColumnConfig(),
	];
};

// auctions_valid
