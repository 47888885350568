import { Card } from '@monorepo/base/src/components/card/card';
import { observer } from 'mobx-react';
import styles from './bidder-targeting.module.scss';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../../stores';
import { CampaignTargetingLinkChoicePolicy } from '../../../../models/bidder-campaign/bidder-campaign.types';
import { Toggle } from '@monorepo/base/src/components/form/toggle/toggle';
import { Statuses } from '../../../../enums/status';
import { sentenceCase } from 'change-case';
import { Spacer } from '@monorepo/base/src/components/spacer/spacer';
import { BidderCampaignTargetingLinkModel } from '../../../../models/bidder-campaign/bidder-campaign-targeting-link.model';
import { Dropdown } from '@monorepo/base/src/components/dropdown/dropdown';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { Textarea } from '@monorepo/base/src/components/form/textarea/textarea';

interface ITargetingLinkProps {
	targetingLink: BidderCampaignTargetingLinkModel;
	policy: CampaignTargetingLinkChoicePolicy;
}
export const TargetingLink = observer(({ targetingLink, policy }: ITargetingLinkProps) => {
	const { bidderStore } = useStores<IAdminxStore>();
	const publishers = bidderStore.publishersList.getData()?.publishers || [];
	const publishersNameToId = publishers.reduce((acc, publisher) => {
		acc[publisher.name] = publisher.id;
		return acc;
	}, {} as Record<string, id>);
	const onSelectPublisher = (selectedPublisher: Set<string> | undefined) => {
		const publisherName = selectedPublisher?.values().next().value;
		const publisherId = publishersNameToId[publisherName];
		targetingLink.pid = publisherId;
	};

	const selectedPublisherName = publishers.find(({ id }) => id === targetingLink.pid)?.name;

	const renderTargetingLinkToggle = () => {
		return (
			<Toggle
				label={targetingLink.status === Statuses.Active ? sentenceCase(Statuses.Active) : sentenceCase(Statuses.Paused)}
				onChange={status => {
					targetingLink.status = status ? Statuses.Active : Statuses.Paused;
				}}
				defaultValue={targetingLink.status === Statuses.Active}
			/>
		);
	};

	return (
		<Card title={`Targeting Link - (${targetingLink.yid || 'no YID'})`} titleChildren={renderTargetingLinkToggle()} collapsible>
			{policy === CampaignTargetingLinkChoicePolicy.PerPublisher && (
				<Dropdown
					required={true}
					disabled={targetingLink.id !== 0}
					defaultOption={selectedPublisherName}
					label={'Select a Publisher'}
					options={publishers.map(({ name: label }) => label)}
					onSelect={onSelectPublisher}
				/>
			)}
			<Spacer />
			<Textarea
				value={targetingLink.dest_link}
				placeholder={'Destination Link'}
				onValue={value => {
					targetingLink.dest_link = value;
				}}
			/>
			<Spacer />
			<Toggle
				label={'Encode URL'}
				onChange={encodeUrl => {
					targetingLink.encode_url = encodeUrl;
				}}
				defaultValue={targetingLink.encode_url}
			/>
			<Input
				className={`${styles.inputWithHint}`}
				value={targetingLink.yid}
				placeholder={'YID'}
				onValue={value => {
					targetingLink.yid = value;
				}}
			/>
			<div className={styles.inputsWrapper}>
				{policy === CampaignTargetingLinkChoicePolicy.ByWeight && (
					<Input
						className={`${styles.inputWithHint}`}
						value={`${targetingLink.weight || 0}`}
						title={'Weight'}
						placeholder={'Weight'}
						type="number"
						onValue={value => {
							targetingLink.weight = parseFloat(value || '0');
						}}
					/>
				)}
				{policy !== CampaignTargetingLinkChoicePolicy.ByWeight && (
					<Input
						className={`${styles.inputWithHint}`}
						value={`${targetingLink.max_conversions || 0}`}
						title={'Max Conversions'}
						placeholder={'Max Conversions'}
						type="text"
						onValue={value => {
							targetingLink.max_conversions = parseInt(value || '0');
						}}
					/>
				)}
			</div>
			<Spacer />
			<Card title={`CID's - (${targetingLink.cids?.length ?? 0})`} collapsible>
				{targetingLink.cids?.map((cid, i) => {
					return (
						<div key={cid.id || i} className={styles.inputsWrapper}>
							<Input
								className={`${styles.inputWithHint}`}
								value={`${cid.cid}`}
								placeholder={'cid'}
								title={'cid'}
								onValue={value => {
									cid.weight = parseInt(value) || 0;
								}}
							/>
							<Input
								className={`${styles.inputWithHint}`}
								value={`${cid.weight}`}
								placeholder={'Weight'}
								title={'Weight'}
								onValue={value => {
									cid.weight = parseInt(value) || 0;
								}}
							/>
						</div>
					);
				})}
				<Spacer />
				<div className={styles.createButtonWrapper}>
					<Icon size={'1.5rem'} className={styles.addIconButton} isMFP onClick={targetingLink.addNewCid}>
						plus
					</Icon>
				</div>
			</Card>
		</Card>
	);
});
